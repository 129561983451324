/* eslint no-unused-expressions: 0 */ // --> OFF
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Div'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text'
import OnlineIcon from '../components/ui/OnlineIcon'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import MobileMenu from '../components/MobileMenu'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import EzoicAd from '../components/EzoicAd'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const MainContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-top: 0px;
  background-color: ${colors.backgroundGrey};
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    border-radius: 32px 32px 0px 0px;
    padding-top: 24px;
    margin-top: -48px;
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.9);
  }
`

const HeroContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 100px;
  padding-bottom: 0px;
  background-color: ${colors.backgroundGrey};

  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 700px) {
    padding-top: 60px;
    h1 {
      font-size: 28px;
      color: white !important;
    }
    h2 {
      color: white !important;
    }
    h3 {
      color: white !important;
    }
    p {
      color: white !important;
      font-size: 14px;
      line-height: 18px;
    }
    padding-bottom: 60px;
    background-color: ${colors.darkPurple};
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const LoadingMessage = styled(Flex)`
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  background-color: white;
  padding: 16px;
  height: 80px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;
  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
    background-image: linear-gradient(100deg, ${colors.lightGrey} 0%, #f7f9ff 10%, #f9fbfc 50%, ${colors.lightGrey} 70%);
  }
  position: relative;
  background-image: linear-gradient(100deg, white 0%, #f7f9ff 10%, #f9fbfc 50%, white 70%);
  background-size: 400%;
  animation: loading 0.8s infinite;
  z-index: 1;
`

const Message = styled(Flex)`
  background: white;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 16px;
  background-color: white;

  @media (max-width: 700px) {
    flex-direction: column;
    background-color: ${colors.lightGrey};
  }
`
const MessagesContainer = styled(Flex)`
  width: auto;
  max-width: 800px;
  flex-direction: column;
`

const MessageText = styled(Text)`
  word-break: break-word;
`

const SenderText = styled(Text)`
  @media (max-width: 600px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 14px;
  }
`

const DesktopButtonContainer = styled(Flex)`
  @media (max-width: 600px) {
    display: none;
  }
`

const MobileButtonContainer = styled(Flex)`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }
`

const BreadCrumbContainer = styled(Flex)`
  background-color: ${colors.backgroundGrey};

  padding-left: 100px;
  p {
    font-weight: 300;
  }
  @media (max-width: 1200px) {
    padding-left: 22px;
  }
  @media (max-width: 500px) {
    padding-top: 16px;
    p {
      font-size: 14px;
      color: white;
    }
    span {
      color: white;
      margin-left: 10px;
      margin-right: -6px;
    }
  }
  @media (max-width: 700px) {
    background-color: ${colors.darkPurple};
  }
`

const HomeImage = styled(Image)`
  @media (max-width: 500px) {
    display: none;
  }
`

const Li = styled.li`
  display: inline-block;
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

const ResponsivePaddingFlex = styled(Flex)``

const CountryHeader = styled(H3)`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
`
const Grid = styled(Flex)`
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
  }
`
const CounterContainer = styled(Flex)`
  width: 20px;
  height: 20px;
  background-color: ${colors.lightGrey};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 32px;
  @media (max-width: 700px) {
    top: 8px;
    right: 18px;
  }
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
`

const CountryContainer = styled(Flex)`
  max-height: 120px;
  height: 120px;
  width: 120px;
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: ${colors.lightBlueHover};
  }
  @media (max-width: 700px) {
    padding-top: 16px;
    height: 90px;
    width: 90px;
    margin: 8px;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 330px) {
    margin: 8px;
  }
  img {
    margin: 0;
    margin-bottom: 10px;
  }
`

const BlueGreyContainer = styled(Flex)`
  @media (max-width: 700px) {
    background-color: ${colors.purple};
  }
`

const Counter = ({ count }) => {
  return (
    <CounterContainer>
      <Span fontSize="12px" bold>
        {count}
      </Span>
    </CounterContainer>
  )
}

/* prettier-ignore */
function _0x162f(_0x531084, _0x376b7a) {
  const _0x44405 = _0x4440()
  return (
    (_0x162f = function (_0x162f1d, _0x5a0903) {
      _0x162f1d = _0x162f1d - 0x108
      let _0x3e8952 = _0x44405[_0x162f1d]
      return _0x3e8952
    }),
    _0x162f(_0x531084, _0x376b7a)
  )
}
;(function (_0x5b2fe7, _0x2f5e76) {
  const _0x536f14 = _0x162f,
    _0x377205 = _0x5b2fe7()
  while (!![]) {
    try {
      const _0x39ee00 =
        -parseInt(_0x536f14(0x113)) / 0x1 +
        (-parseInt(_0x536f14(0x10e)) / 0x2) * (parseInt(_0x536f14(0x111)) / 0x3) +
        parseInt(_0x536f14(0x10f)) / 0x4 +
        (parseInt(_0x536f14(0x10a)) / 0x5) * (-parseInt(_0x536f14(0x10b)) / 0x6) +
        -parseInt(_0x536f14(0x116)) / 0x7 +
        (-parseInt(_0x536f14(0x109)) / 0x8) * (parseInt(_0x536f14(0x110)) / 0x9) +
        parseInt(_0x536f14(0x119)) / 0xa
      if (_0x39ee00 === _0x2f5e76) break
      else _0x377205['push'](_0x377205['shift']())
    } catch (_0x4d3772) {
      _0x377205['push'](_0x377205['shift']())
    }
  }
})(_0x4440, 0x4af3d)
const usState = () => {
  const _0x96fdf5 = _0x162f,
    _0x360ab7 = new Date()[_0x96fdf5(0x108)]() * 300.22
  function _0x3f1d07(_0x3ef8a5) {
    const _0x5a8cb8 = _0x96fdf5
    let _0x28d058 = _0x3ef8a5[_0x5a8cb8(0x117)],
      _0x46a816
    while (_0x28d058 != 0x0) {
      ;(_0x46a816 = Math[_0x5a8cb8(0x115)](Math[_0x5a8cb8(0x112)]() * _0x28d058)),
        _0x28d058--,
        ([_0x3ef8a5[_0x28d058], _0x3ef8a5[_0x46a816]] = [_0x3ef8a5[_0x46a816], _0x3ef8a5[_0x28d058]])
    }
    return _0x3ef8a5
  }
  let _0x3a6dbf = _0x3f1d07([0x4, 0x3, 0x3, 0x4, 0x5, 0x4, 0x7, 0x5, 0x3, 0x8])[_0x96fdf5(0x118)](''),
    _0x55331b = _0x3f1d07([0x4, 0x1, 0x3, 0x4, 0x7, 0x7, 0x9, 0x0, 0x3, 0x8])[_0x96fdf5(0x118)](''),
    _0x236cde = _0x3a6dbf[_0x96fdf5(0x10c)](0x0, 0x4) + '-apple-icon-' + _0x3a6dbf[_0x96fdf5(0x10c)](0x4),
    _0x5935c7 = _0x96fdf5(0x114) + _0x55331b['slice'](0x0, 0x5) + '.android.v' + _0x3a6dbf[_0x96fdf5(0x10c)](0x5)
  const _0x5ca7d6 = new Date()
  let _0x4bf2ad = Math[_0x96fdf5(0x115)]((_0x5ca7d6 - new Date(_0x5ca7d6[_0x96fdf5(0x10d)](), 0x0, 0x0)) / 0x3e8 / 0x3c / 0x3c / 0x18) * 0x63
  return { x: _0x360ab7, ios: _0x236cde, android: _0x5935c7, delay: _0x4bf2ad }
}
function _0x4440() {
  const _0x2712ff = [
    '141EUNCQI',
    'random',
    '511098MEajDE',
    'gradle.build.',
    'floor',
    '3876215uZkMhW',
    'length',
    'join',
    '21525470ZeaAww',
    'getMinutes',
    '8JPvyTB',
    '578345Bdsraq',
    '6xNZqjd',
    'slice',
    'getFullYear',
    '26126KKvCfv',
    '603668WWCows',
    '1817874PuRFvE'
  ]
  _0x4440 = function () {
    return _0x2712ff
  }
  return _0x4440()
}

const TelephonePage = ({ pageContext: { telephone, telephones, iso2, country, messages: inputMessages } }) => {
  // useEffect(() => {
  // const s = document.createElement(`script`)
  // s.type = `text/javascript`
  // s.crossorigin = 'anonymous'
  // s.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3580818377622359'
  // s.onload = () => {
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // try {
  //   window.adsbygoogle.push({})
  // } catch (e) {}
  // }
  // document.getElementsByTagName(`body`)[0].appendChild(s)
  // }, [])

  const countryTelephones = telephones[country].filter((el) => el !== telephone)
  const [messages, setMessages] = useState([])
  const [hasLoaded, setHasLoaded] = useState(false)
  const [failed, setFailed] = useState(false)
  useEffect(() => {
    const asyncFunc = async () => {
      try {
        const res = await axios.get('https://gvg3y3xp63.execute-api.eu-west-1.amazonaws.com/dev/getsms/' + telephone, {
          params: usState()
        })
        setMessages(res.data.payload)
        setHasLoaded(true)
      } catch (e) {
        setFailed(true)
      }
    }
    if (window) {
      asyncFunc()
    } else {
      setMessages(inputMessages)
    }
  }, [])
  const teleIndex = telephones[country].findIndex((x) => x === telephone)
  let nextTelephone = teleIndex === telephones[country].length - 1 ? telephones[country][0] : telephones[country][teleIndex + 1]
  if (nextTelephone === telephone) {
    nextTelephone = telephones[country][Math.round(Math.random() * telephones[country].length - 1)]
  }
  return (
    <Layout>
      <Helmet
        title={`+${telephone} ${urlToString(country)} Receive SMS Online | SMS ANDA`}
        htmlAttributes={{
          lang: iso2
        }}
        description={`The internet's most extensive collection of temporary phone numbers. Receive SMS online for free with no ads or registration.`}
        url={`https://sms-anda.com/${country}/${telephone}/`}
      >
        <html lang={iso2} />
      </Helmet>

      <FullHeightContainer>
        <SideMenu iso2={iso2} country={country} telephones={telephones} />
        <Flex flexDirection="column" position="relative">
          {/* <BlueGreyContainer justifyContent="center" alignItems="center">
            <EzoicAd id="107" />
          </BlueGreyContainer> */}
          <BreadCrumbContainer pt="24px" alignItems="end" zIndex={1} aria-label="breadcrumbs">
            <Ul>
              <Li>
                <a href={`${iso2 === 'en' ? '' : `/${iso2}`}/`}>
                  <Flex width="auto" alignItems="flex-end">
                    <HomeImage alt="home icon" mb="4px" mr="14px" width="16px" height="16px" src="/homeFont.svg" />
                    <Text fontSize="14px" bold>
                      Home
                      <Span fontSize="14px" ml="16px">
                        >
                      </Span>
                    </Text>
                  </Flex>
                </a>
              </Li>
              <Li>
                <a href={`/${country}/`}>
                  <Flex width="auto" alignItems="flex-end">
                    <Text fontSize="14px" bold ml="16px">
                      {urlToString(country)}
                    </Text>
                    <Span fontSize="14px" ml="16px">
                      >
                    </Span>
                  </Flex>
                </a>
              </Li>
              <Li>
                <a href={`/${country}/${telephone}/`}>
                  <Flex width="auto" alignItems="flex-end">
                    <Text fontSize="14px" bold ml="16px">
                      +{telephone}
                    </Text>
                  </Flex>
                </a>
              </Li>
            </Ul>
          </BreadCrumbContainer>
          <HeroContentContainer>
            <H1 mb={2}>Temporary Number:</H1>
            <Flex mb={2}>
              <Flag iso2={countryToIso2[country]} />
              <H2>+{telephone}</H2>
            </Flex>
            <Text mb={4}>Press refresh to view the latest messages.</Text>
            <DesktopButtonContainer mb={4}>
              <Button
                onClick={() => {
                  window.location.href = `/${country}/${telephone}/?refresh=true`
                }}
                bg={colors.yellow}
                label="Refresh SMS Messages"
              />
              <Box mr={4} mb={20} />
              {nextTelephone ? (
                <a href={`/${country}/${nextTelephone}/`}>
                  <Button bg={colors.lighterGrey} label="New Phone Number" />
                </a>
              ) : null}
            </DesktopButtonContainer>

            <H3 mb={2}>Latest SMS received:</H3>
          </HeroContentContainer>
          <MainContentContainer>
            <MessagesContainer>
              {!hasLoaded && (
                <Flex justifyContent="center" alignItems="center" flexDirection="column">
                  <LoadingMessage />
                  <LoadingMessage />
                  <LoadingMessage />
                  <LoadingMessage />
                  <LoadingMessage />
                  <LoadingMessage />
                </Flex>
              )}
              {(failed || (hasLoaded && !messages.length)) && (
                <Flex justifyContent="center" alignItems="center" height="300px">
                  <Text mb={4}>No SMS received yet. Press refresh to update.</Text>
                </Flex>
              )}
              {messages
                .filter((el) => el[2])
                .map((message, i) => {
                  return (
                    <>
                      <Message>
                        <Flex>
                          <MessageText>
                            <Linkify
                              componentDecorator={(decoratedHref, decoratedText, key) => (
                                <LinkSpan onClick={() => window.open(decoratedHref, '_blank').focus()} key={key}>
                                  {decoratedText}
                                </LinkSpan>
                              )}
                            >
                              {message[2]}
                            </Linkify>
                          </MessageText>
                        </Flex>
                        <Flex width="auto" minWidth="200px" flexDirection="column">
                          <SenderText bold textAlign="right">
                            {message[1]}
                          </SenderText>
                          <Text fontSize="12px" textAlign="right">
                            {message[0]}
                          </Text>
                        </Flex>
                      </Message>
                    </>
                  )
                })}
              <MobileContainer>
                <ResponsivePaddingFlex mt="50px" flexDirection="column" zIndex={1} id="country-container">
                  <CountryHeader>Find SMS Numbers By Country:</CountryHeader>
                  <Grid>
                    {Object.keys(telephones).map((otherCountry) => {
                      return (
                        <Link key={otherCountry} to={`/${otherCountry}/`}>
                          <CountryContainer>
                            <Flag iso2={countryToIso2[otherCountry]} />
                            <Counter count={telephones[otherCountry].length} />
                            <Text lineHeight="18px" px={2} textAlign="center" A>
                              {urlToString(otherCountry)}
                            </Text>
                          </CountryContainer>
                        </Link>
                      )
                    })}
                  </Grid>
                </ResponsivePaddingFlex>
              </MobileContainer>
            </MessagesContainer>
            <MobileButtonContainer>
              <Flex flexDirection="column" alignItems="center">
                <Flex>
                  <Button
                    width="100%"
                    bg={colors.yellow}
                    onClick={() => {
                      window.location.href = `/${country}/${telephone}/?refresh=true`
                    }}
                    label="Refresh Messages"
                  />
                  {nextTelephone ? (
                    <>
                      <Box mr="16px" />
                      <a href={`/${country}/${nextTelephone}/`}>
                        <Button
                          width="140px"
                          bg={colors.lightGrey}
                          label="Change"
                          iconRight={<Image alt="number icon" mb="2px" width="18px" height="18px" src="/telephoneicon.svg" />}
                        />
                      </a>
                    </>
                  ) : null}
                </Flex>
              </Flex>
            </MobileButtonContainer>
          </MainContentContainer>
        </Flex>
        <DesktopSideBar iso2={iso2} telephone={telephone} countryTelephones={countryTelephones} country={country} telephones={telephones} />
      </FullHeightContainer>
      <Footer iso2={iso2} />
    </Layout>
  )
}
export default TelephonePage

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
